
import { getDatetime } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ReportsReportCard",
  components: {},
  props: {
    item: Object,
    isLocked: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    datetime: function (): string {
      return getDatetime(
        this.item.updated_datetime || this.item.created_datetime
      );
    },
    creator: function (): string {
      return this.item.created_by;
    },
  },
  methods: {
    goToReport() {
      this.$router.push("/reports/" + this.item.id);
    },
  },
});


import {
  IAssociate,
  IProject,
  IProjectReport,
  multiApiOperations,
  projects,
} from "@/api";
import Vue from "vue";
import ReportCard from "@/components/reports/ReportsReportCard.vue";
import { Actions } from "@/store/models";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default Vue.extend({
  name: "Reports",
  components: {
    ReportCard,
    ConfirmationDialog,
  },
  data() {
    return {
      items: [] as IProjectReport[],
      searchQuery: "",
      newItemDialog: false,
      selected: new Set<number>(),
      userId: "",
      customerName: "",
      deleteReportsDialog: false,
    };
  },
  computed: {
    companyId: function (): number {
      return this.$store.state.company.id;
    },
    users: function (): IAssociate {
      return this.$store.state.company.associates;
    },
    customerNames: function (): string[] {
      let result = (this.$store.state.company?.projects?.map(
        (item: IProject) => item?.customer_name
      )) || [];
      result = result.filter((r:string) => r)
      return result
    },
    filteredItems: function (): IProjectReport[] {
      const searchQuery = this.searchQuery?.toLowerCase();
      let filtered = this.items.filter((item) => {
        return (
          item.name?.toLowerCase().includes(searchQuery) ||
          item.project.customer_name?.toLowerCase().includes(searchQuery) ||
          item.project.name?.toLowerCase().includes(searchQuery) ||
          item.project.address?.toLowerCase().includes(searchQuery) ||
          item.created_by?.toLowerCase().includes(searchQuery)
        );
      });
      if (this.userId) {
        filtered = filtered.filter((item) => item.created_by === this.userId);
      }
      if (this.customerName) {
        filtered = filtered.filter(
          (item) => item.project.customer_name === this.customerName
        );
      }
      return filtered;
    },
  },
  mounted() {
    this.getReports();
  },
  methods: {
    async getReports() {
      try {
        this.items = (await projects.reports.getByCompany(this.companyId)).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async deleteReports() {
      try {
        await multiApiOperations(projects.reports.delete, [...this.selected]);
        this.getReports();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    select(id: number) {
      if (!this.selected.delete(id)) {
        this.selected.add(id);
      }
    },
    goToReport(id: number) {
      this.$router.push(`/reports/${id}`);
    },
    resetFilter() {
      this.searchQuery = "";
      this.userId = "";
      this.customerName = "";
    },
  },
});
